import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faChrome as fabChrome,
	faDiscord as fabDiscord,
	faEdge as fabEdge,
	faFirefox as fabFirefox,
	faGithub as fabGithub,
	faOpera as fabOpera,
	faCcPaypal as fabPaypal,
	faTwitch as fabTwitch,
	faTwitter as fabTwitter,
	faYoutube as fabYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faObjectUngroup, faCircleCheck as farCircleCheck } from "@fortawesome/free-regular-svg-icons";
import {
	faArrowLeft,
	faBars,
	faBellConcierge,
	faBookSparkles,
	faBox,
	faBrush,
	faBullseye,
	faBullseyePointer,
	faCakeSlice,
	faCalendarAlt,
	faCheck,
	faCheckCircle,
	faCheckDouble,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronsDown,
	faChevronsRight,
	faCircle,
	faClock,
	faCodeBranch,
	faColumns,
	faCopy,
	faCreditCard,
	faCube,
	faDownload,
	faEllipsisH,
	faEllipsisV,
	faEnvelope,
	faExclamation,
	faExclamationTriangle,
	faExternalLinkAlt,
	faEye,
	faEyeSlash,
	faFaceSunglasses,
	faFilter,
	faFire,
	faFlag,
	faGavel,
	faGear,
	faGift,
	faGlobe,
	faHashtag,
	faHexagonPlus,
	faLanguage,
	faLayerGroup,
	faListCheck,
	faLock,
	faMegaphone,
	faMerge,
	faMinus,
	faMoon,
	faObjectGroup,
	faPalette,
	faPen,
	faPenField,
	faPersonRunningFast,
	faPlus,
	faRectangleWide,
	faReply,
	faRotateRight,
	faSave,
	faSeal,
	faSearch,
	faSlash,
	faSmile,
	faSquare,
	faSquareQuestion,
	faStar,
	faSunBright,
	faSync,
	faTag,
	faTicket,
	faTimer,
	faTimes,
	faTrash,
	faTreeChristmas,
	faTrophy,
	faUndo,
	faUnlock,
	faUser,
	faUserClock,
	faUserLock,
	faUserMinus,
	faUserPen,
	faUserPlus,
	faUserSlash,
	faUsersCog,
	faWaveformLines,
	faWrench,
} from "@fortawesome/sharp-solid-svg-icons";

library.add(
	faArrowLeft,
	faBars,
	fabChrome,
	fabDiscord,
	fabEdge,
	fabFirefox,
	fabGithub,
	faBookSparkles,
	fabOpera,
	faBox,
	fabPaypal,
	faBrush,
	fabTwitch,
	fabTwitter,
	faBullseye,
	faBullseyePointer,
	fabYoutube,
	faCakeSlice,
	faCalendarAlt,
	faCheck,
	faCheckCircle,
	faCheckDouble,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronsDown,
	faChevronsRight,
	faCircle,
	faClock,
	faCodeBranch,
	faColumns,
	faCopy,
	faCopy,
	faCreditCard,
	faCube,
	faDownload,
	faEllipsisH,
	faEllipsisV,
	faEnvelope,
	faExclamation,
	faExclamationTriangle,
	faExternalLinkAlt,
	faEye,
	faEyeSlash,
	faFaceSunglasses,
	faFilter,
	faFire,
	faFlag,
	faGavel,
	faGear,
	faGift,
	faGlobe,
	faHashtag,
	faHexagonPlus,
	faLanguage,
	faLayerGroup,
	faListCheck,
	faLock,
	faMegaphone,
	faMinus,
	faMoon,
	faObjectGroup,
	faObjectUngroup,
	faPalette,
	faPen,
	faPenField,
	faPlus,
	farCircleCheck,
	faRectangleWide,
	faReply,
	faRotateRight,
	faSave,
	faSeal,
	faSearch,
	faSearch,
	faSlash,
	faSmile,
	faSquare,
	faSquareQuestion,
	faStar,
	faSunBright,
	faSync,
	faTag,
	faTicket,
	faTimer,
	faTimes,
	faTrash,
	faTreeChristmas,
	faTrophy,
	faUndo,
	faUnlock,
	faUser,
	faUserClock,
	faUserLock,
	faUserMinus,
	faUserPen,
	faUserPlus,
	faUsersCog,
	faUserSlash,
	faWaveformLines,
	faWrench,
	faBellConcierge,
	faMerge,
	faPersonRunningFast,
);
