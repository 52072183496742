<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" viewBox="0 0 1015.99 939.38">
		<g>
			<path
				d="M870.64,710.49c50.61-68.82,57.25-163.75,17.14-239.1-37.33-71.7-113.76-119.34-194.61-121-94.78-3-183.3,55.75-217.48,144.17-38.85,98.92-2.86,213,85.73,271.63,78,52.23,183.22,50.18,258.89-5.39L965.66,906.16,1016,855.83ZM687,732.91c-102,0-176.4-96.68-150.63-195.3C553.87,469.85,617,421,687,421.5c70-.46,133.17,48.35,150.63,116.11C863.44,636.23,789,732.9,687,732.91Z"
				fill="currentColor"
			/>
			<path
				d="M389.72,556.09H117.18L0,939.38H823.26c-8.58-28.08-17.47-57.15-26-85.22C592,934.47,375.31,775.85,389.72,556.09"
				fill="currentColor"
			/>
			<path
				d="M416.18.05c-130.55-2.64-238.48,102.82-239,233.34C176.47,362,280.73,467.79,409.36,468.87c37.69-98,127.91-170.45,231.68-186.16C671.22,138.94,563,3,416.18.05"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
